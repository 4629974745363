


























































































































































import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { MyCharacterViewmodel } from '@/modules/my-character/viewmodels/my-character-viewmodel'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    CharacterCard: () => import('../components/character-card.vue'),
    CharacterDetailDialog: () => import('../components/character-detail-dialog.vue'),
  },
})
export default class MyCharacter extends Vue {
  rareLevels = ['Mythic', 'Legendary', 'Epic', 'Rare', 'Uncommon', 'Common']
  sortById = ['ID (Asc)', 'ID (Desc)', 'Power (Asc)', 'Power (Desc)']
  @Provide() vm = new MyCharacterViewmodel()
  wallet = walletStore

  id = ''
  rareLevel = ''
  sortBy: 'ID (Asc)' | 'ID (Desc)' | 'Power (Asc)' | 'Power (Desc)' | '' = ''
  search() {
    this.vm.searchMyCharacters({
      id: this.id,
      rareLevel: this.rareLevel,
      page: 1,
    })
  }

  sort() {
    this.vm.sortBy(this.sortBy)
  }
}

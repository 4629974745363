import { localdata } from '@/helpers/local-data'
import { appProvider } from './../../../app-providers'
import { HeroNftModel } from '@/models/hero-nft-model'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { bigNumberHelper } from '@/helpers/bignumber-helper'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { action, computed, IReactionDisposer, observable, reaction, when } from 'mobx'
import { asyncAction, task } from 'mobx-utils'
import moment from 'moment'
import { Zero } from '@/constants'
import { Subject, timer } from 'rxjs'
import { ApiService } from '@/service/api-service'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import Web3 from 'web3'
import { chunk, isEmpty, sortBy, orderBy } from 'lodash-es'
import { EmpirelandHeroNftHandler } from '@/blockchainHandlers/empirelandHeroNftHandler'

export class MyCharacterViewmodel {
  _disposers: IReactionDisposer[] = []
  private _unsubcrible = new Subject()
  @observable heroNftIds: any = []
  @observable heroNfts?: Array<HeroNftModel> = []
  @observable charactersPerPage = 8
  @observable totalFilteredCharacter?: number = 0

  searchParams: any = {}
  filteredCharacters: HeroNftModel[] = []
  @observable slicedMyCharacters: HeroNftModel[] = []
  @observable totalOwnedCharacterPage = 0
  @observable currentPage = 1

  @observable showDetailDialog = false
  @observable selectedCharacter: HeroNftModel = {}

  heroNftHandler: any

  constructor() {
    this.loadData()
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.isChainIdValid) {
            this.loadData()
            this.heroNftHandler?.injectMetamask(walletStore.web3!)
          }
        }
      ),
    ]
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this._disposers.forEach((d) => d())
  }

  @asyncAction *loadData() {
    loadingController.increaseRequest()
    const heroNftHandler = new EmpirelandHeroNftHandler()
    this.heroNftHandler = heroNftHandler
    try {
      if (!walletStore.isMetamask || !walletStore.account) return

      this.heroNftIds = yield heroNftHandler.getHeroNftIds()
      if (this.heroNftIds && this.heroNftIds.length > 0) {
        const groups = chunk(this.heroNftIds, 100)
        const characters: HeroNftModel[] = []
        for (const nftIds of groups) {
          const nfts = yield appProvider.api.heroNft.find<HeroNftModel>({
            ID_in: nftIds,
            _limit: -1,
          })
          characters.push(...nfts)
        }
        this.heroNfts = characters.sort((a, b) => +a.ID! - +b.ID!) // asc
      }
      this.searchMyCharacters({})
    } catch (error) {
      snackController.commonError(error)
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @action sortBy(type: 'ID (Asc)' | 'ID (Desc)' | 'Power (Asc)' | 'Power (Desc)' | '') {
    if (type === 'ID (Asc)') {
      this.heroNfts = orderBy(this.heroNfts, [(o) => Number(o.ID)])
    } else if (type === 'ID (Desc)') this.heroNfts = sortBy(this.heroNfts, [(o) => -Number(o.ID)])
    else if (type === 'Power (Asc)') this.heroNfts = sortBy(this.heroNfts, [(o) => Number(o.rarityScore)])
    else if (type === 'Power (Desc)') this.heroNfts = sortBy(this.heroNfts, [(o) => -Number(o.rarityScore)])
    this.searchMyCharacters({ page: 1 })
  }

  @action.bound searchMyCharacters(params) {
    this.searchParams = params
    if (isEmpty(this.searchParams)) {
      this.totalFilteredCharacter = this.heroNfts?.length
      this.filteredCharacters = [...this.heroNfts!]
    } else {
      this.currentPage = this.searchParams.page

      let characters = this.heroNfts as any
      if (this.searchParams.id)
        characters = characters.filter((character) => character.ID.includes(this.searchParams.id))

      if (this.searchParams.rareLevel)
        characters = characters.filter((character) => character.rareLevel == this.searchParams.rareLevel)

      this.filteredCharacters = characters
      this.totalFilteredCharacter = characters.length
    }
    this.slicedMyCharacters = this.filteredCharacters.slice(
      (this.currentPage - 1) * this.charactersPerPage,
      this.currentPage * this.charactersPerPage
    )

    this.calculateTotalPage()
  }

  changeMyCharacterPage(page) {
    this.currentPage = page
    this.searchMyCharacters({ ...this.searchParams, page })
  }

  calculateTotalPage() {
    if (this.totalFilteredCharacter! % this.charactersPerPage! == 0)
      this.totalOwnedCharacterPage = this.totalFilteredCharacter! / this.charactersPerPage!
    else this.totalOwnedCharacterPage = Math.floor(this.totalFilteredCharacter! / this.charactersPerPage!) + 1
  }

  @action.bound openDetailDialog(character) {
    this.showDetailDialog = true
    this.selectedCharacter = { ...character }
  }

  @action.bound closeDetailDialog() {
    this.showDetailDialog = false
    this.selectedCharacter = {}
  }

  @computed get hasCharacter() {
    return this.totalFilteredCharacter! > 0
  }
}
